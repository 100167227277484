<template>
    <div class="px-3 py-4">
        <h1>
            <i class="fas fa-book mr-2 my-1"></i>
                {{
                    $store.state.language === "en"
                        ? "Rules And Regulations"
                        : "စည်းကမ်းချက်များ"
                }}
            </h1>
        <ul>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (1) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၁) </span>ဘော်ဒီများကိုအနည်းဆုံး (10) ဘတ်မှ အများဆုံး (20000) ဘတ်အထိကစားနိုင်ပါသည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (2) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၂) </span>ပွဲစဉ်များကို  ပွဲကြီး ပွဲသေး အရောင်နဲ့ ပိုင်းခြားပြပြီး အကောက်မတူပါ။ <br>
                 <b class="ml-4">ပွဲကြီး - 5%</b> <br>
                 <b class="ml-4">ပွဲသေး - 5%</b>
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (3) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၃) </span>မောင်း အနည်း ဆုံး (10) ဘတ်မှ အများဆုံး (200) ဘတ်အထိ ကစားနိုင်သည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (4) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၄) </span>မောင်းများကို အနည်းဆုံး ( ၂ ) ပွဲမှ ( ၁၂ ) ပွဲအထိ ကစားနိုင်ပါသည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (4) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၅) </span>စုံ/မ အကောက်ခ 10% ဖြစ်ပါသည်။ 
            </li>
            <!-- <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (6) <span class="text-danger"> * </span></h3>
                ဂိုးရလဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting Company (1. ibet789, 2. 365, 4. 368) များဖြင့် အလျော်အစားပြုလုပ်၍ အဆိုပါ Company များမှ 9:00 AM ထိမထွက်ရှိပါက 1. Flash Score, 2. Live score အားကြည့်ရှူ့ပြီးမတွေ့ပါက သက်ဆိုင်ရာအဖွဲ့ချူပ်၏ ရလဒ်ကို အတည်ပြုပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (7) <span class="text-danger"> * </span></h3>
                ပွဲစဉ် တစ်ချို့၏ ဂိုးရလဒ်များအား တည်ငြိမ်မူရှိစေရန် စောင့်ပေးရသဖြင့် အချိန်အနည်းငယ်ဖြစ်စေ နောက်တစ်နေ့ ပွဲစဉ်မစတင်မှီအထိဖြစ်စေ စောင့်ဆိုင်းပေးရမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (8) <span class="text-danger"> * </span></h3>
                ဂိုးရလဒ်များထည့်သွင်းရာတွင် အချို့ပွဲစဉ် များသည် ညပိုင်းတွင်ရလဒ်တစ်မျိုးထွက်ပေါ်ပြီးမနက်ပိုင်းတွင်ရလဒ်တစ်မျိုးဖြင့်ပြင်ဆင်ပြောင်းလဲထွက်ပေါ်ခြင်းများရှိပါက နေ့ခင်း (12:00pm) အထိသာအကြောင်းကြားခြင်းများအား လက္ခံပြင်ဆင်ပြောင်းလဲပေးပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (9) <span class="text-danger"> * </span></h3>
                Goal ရလဒ်များအား (12:00pm) ကျော်လွန်သွားပါကမပြင်ဆင်ပဲ မူလရလဒ်ကိုသာ အတည်ပြုထည့်သွင်းကာ အလျော်အစားပြုလုက်ပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (10) <span class="text-danger"> * </span></h3>
                အချိန်စောကန်သွားသော ပွဲစဉ်များ ရှိခဲ့လျင်အခြား Betting Company များနည်းတူမဟုတ်ပဲ GOLD 549 တွင်မူလအချိန်အတိုင်းဖွင့်လစ်ထားမိပါကအဆိုပါပွဲစဉ်အား cancel match ပြုလုပ်ပါမည်။
            </li> -->
            <li class="my-4">
                <span class="text-danger"> (၆) </span>ပွဲစဉ်များကို မောင်းထိုးရလွယ်ကူစေရန် ပွဲကြီး / ပွဲသေး အရောင်ဖြင့် ပိုင်းခြားပြထားပါသည်။ မောင်းအကောက်<br>
                    <!-- <b class="ml-4">2 သင်း > 12%</b> <br> -->
                    <b class="ml-4">2 သင်း - 8 သင်း > 16%</b> <br>
                    <b class="ml-4">9 သင်း - 12 သင်း > 24%</b> <br>
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (6) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၇) </span>သက်မှတ်ချိန် တစ်ရက်တာအတွင်း ပွဲပျက်(သို့မဟုတ်) မကန်သောပွဲစဉ်များ ရလဒ်မထွက်သောပွဲစဉ်များအား အလျော်အစားမလုပ်ပဲ ထိုးငွေအား ပြန်ပေးပါမည်။
                (မှတ်ချက် - သက်မှတ်ပွဲစချိန်မှ 2 နာရီ အတွင်း မကန်သော အချိို့ပွဲစဉ်များအား ပွဲပျက်ဟု သတ်မှတ်နိုင်သည်။)
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (7) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၈) </span>ဂိုးရလဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting Company မှဂိုးရလဒ်ဖြင့် အလျော်အစားပြုလုပ်ပါမည် ( Ibet789 / 365 )
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (8) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၉) </span>အချိန်စောကန်သွားသော ပွဲစဉ်များအား GOLD 549 Application တွင်မူလအချိန်အတိုင်း အချိန်မပြောင်းပဲ ဖွင့်ထားပါက ပွဲပျက်သက်မှတ်ပါမည်။ အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (9) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၁၀) </span>နည်းပညာချွတ်ယွင်း၍ အခြားသော betting company များနှင့် ပေါက်ကြေးလွန်စွာကွဲလွဲနေပါက cancal match ပြုလုပ်ပါမည်။
            </li>

            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (10) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၁၁) </span>နည်းပညာချွတ်ယွင်း၍ အလျော်အစားမှားပြုလုပ်ထားသော ဘောင်ချာများအား နောက်တစ်နေ့ နေ့ခင်း 12 နာရီမတိုင်ခင် အကြောင်းကြားပေးထားသောဘောင်ချာများသာ တာဝန်ယူဖြေရှင်းပေးမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (11) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၁၂) </span>ပုံမှန်မဟုတ်သော ပွဲစဉ်များပါရှိပါက ၎င်းပွဲစဉ်အား အလျော်အစားမပြုပဲ Cancel Match ပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (12) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၁၃) </span>ဆော့ကစားခြင်းမရှိပဲ ငွေ အထုတ်/အသွင်း ပြုလုပ်သော အကောင့်များအား ငွေပြန်မအမ်းပဲ ပိတ်သိမ်းပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (13) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger"> (၁၄) </span>အငြင်းပွါးဖွယ်ရာ ကိစ္စများပေါ်ပေါက်လာပါက အများနှင့်နိူင်းယှဉ်သုံးသပ်ရသည့်အတွတ် GOLD 549 ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်သည်။
            </li>

            <!-- <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (12) <span class="text-danger"> * </span></h3>
                အငြင်းပွါးဖွယ်ရာ ကိစ္စများပေါ်ပေါက်လာပါက အများနှင့်နိူင်းယှဉ်သုံးသပ်ရသည့်အတွတ် GOLD 549 ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်သည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (13) <span class="text-danger"> * </span></h3>
                NowGoal မှ goal results များနှင့်အလျော်အစားမလုက်ပါ။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (14) <span class="text-danger"> * </span></h3>
                အချိန်စောကန်သောပွဲဖြစ်သော်လည်း အခြား betting company များအတိုင်း GOLD 549 တွင်လိုက်ပြောင်းလဲပါက အဆိုပါပွဲအား အလျှော်အစားပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (15) <span class="text-danger"> * </span></h3>
                Betting Company များတွင် ပွဲကန်ချိန်နောက်ကျ၍ GOLD 549 တွင်ပွဲကန်ချိန်စောနေသောပွဲများကို Cancel Match မပြုလုပ်ပဲ အလျှော်အစားပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (16) <span class="text-danger"> * </span></h3>
                နည်းပညာချွတ်ယွင်း၍ အခြားသော betting company များနှင့် စျေးနူံးပေါက်ကြေး (လွန်စွာ) ကွဲလွဲဖွင့်လှစ်မိပါကယင်းစျေးနူံးဖြင့်ကစားထားသောမောင်းနှင့်ဘော်ဒီများအား Cancel ပြုလုပ်ပြီး အရင်းငွေပြန်ပေးပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (17) <span class="text-danger"> * </span></h3>
                ပုံမှန်မဟုတ်သောပွဲစဉ်များပါရှိပါက ၎င်းပွဲစဉ်အား အလျော်အစားမပြုလုက်ပဲ Cancel Match ပြုလုပ်၍ လောင်းကြေးငွေပြန်အမ်းပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (18) <span class="text-danger"> * </span></h3>
                မောင်းအကောက် % တွင်ပွဲပျက်အားထည့်တွတ်ပါသည် ဥပမာ နစ်မောင်းထိုးထားပြီးတစ်ပွဲပျက်ပါက ဘော်ဒီအကောက်ဖြင့်မကောက်ပဲ နှစ်မောင်းအဖြစ်သာကောက်ပါသည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (19) <span class="text-danger"> * </span></h3>
                သာမန်ကစားခြင်းမဟုတ်ပဲ ကြေးအဟ သီးသန့်ကြားထိုးကစားပါက အဆိုပါ user ၏ ကြားထိုးကြောင်းအား reject ပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (20) <span class="text-danger"> * </span></h3>
                ဆော့ကစားခြင်းမရှိပဲ ငွေအထုတ် / အသွင်းပြုလုပ်သော အကောင့်များအား ငွေပြန်မအမ်းပဲ ပိတ်သိမ်းပါမည်။
            </li> -->
            <!-- style="position: fixed; bottom: 30px; left: 0; right: 0;" -->
            <li >
                <h5 class="text-center text-danger mt-4 mb-5 pb-3">
                    စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။
                </h5>
            </li>
            <li>
                <div class="d-flex justify-content-center my-4" style="position: fixed; bottom: 0; left: 0; right: 0;">
                    <button class="btn btn-danger" @click="logout">အတည်မပြုပါ</button>
                    <button class="btn btn-primary customzie-color" @click="toHome">အတည်ပြုပါသည်</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logoutUser']),
    logout() {
      this.logoutUser();
    },
    toHome() {
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
.customzie-color {
    background-color: #182a4d !important;
    border-color: #182a4d !important;
}
</style>
